import { FC, useEffect, useMemo, useState } from "react"

import { useHistory } from "react-router-dom"

import { useCustomerPartOrders, useCustomerParts, useIsUser, UserId } from "@ncs/ncs-api"
import { Divider, encodeUrlState, useUrlState, TrackingEvent, trackEvent } from "@ncs/web-legos"

import { useShopContext } from "~/contexts"
import { PageContentWrapper, PartPreviewRow, QuickOrderCtaWide } from "~/shared-components"
import { AccountTabName, AccountUrlState } from "~/views/account"
import { PartSearchResultsUrlState } from "~/views/shop/part-search-results"

import { BrandCtaGrid, OrderReceivedModal } from "./components"

export type ShopUrlState = {
	fromOrder: string | null
}

export const Shop: FC = () => {
	const history = useHistory()
	const { data: featuredParts } = useCustomerParts({ params: { featured: true } })
	const { data: orders } = useCustomerPartOrders()
	const [{ recentlyViewedPartIds }] = useShopContext()
	const isDb = useIsUser(UserId.DrivenBrands)

	// If we're redirected here from checkout after finishing an order, show confirmation.
	const [{ fromOrder }] = useUrlState<ShopUrlState>({ fromOrder: null })
	const [showOrderReceivedModal, setShowOrderReceivedModal] = useState(false)

	useEffect(() => {
		if (fromOrder) {
			setShowOrderReceivedModal(true)
			trackEvent(TrackingEvent.CONGRATS)
		}
	}, [fromOrder])

	const previouslyBoughtPartIds = useMemo(() => {
		return orders.reduce<string[]>(
			(idList, order) => [
				...idList,
				...order.lineItems
					.map((lineItem) => lineItem.onlinePart?.id)
					.flatMap((id) => (id ? [id] : [])),
			],
			[]
		)
	}, [orders])

	return (
		<PageContentWrapper breadcrumbs={[{ name: "Shop" }]} title="Shop">
			<BrandCtaGrid />

			{isDb === false && <QuickOrderCtaWide />}

			<Divider my={3} />

			<PartPreviewRow
				heading="Featured Products"
				partIds={featuredParts.map((part) => part.id)}
				button={{
					buttonText: "View featured products",
					icon: "tag",
					onClick: () =>
						history.push({
							pathname: "/shop/search",
							search: encodeUrlState<PartSearchResultsUrlState>({
								featured: true,
							}),
						}),
				}}
			/>

			<PartPreviewRow heading="Recently Viewed Products" partIds={recentlyViewedPartIds} />

			{!isDb && (
				<PartPreviewRow
					heading="Your Recent Purchases"
					partIds={previouslyBoughtPartIds}
					button={{
						buttonText: "View your orders",
						icon: "box",
						onClick: () =>
							history.push({
								pathname: "/account",
								search: encodeUrlState<AccountUrlState>({
									tab: AccountTabName.Orders,
								}),
							}),
					}}
				/>
			)}

			<OrderReceivedModal
				isOpen={showOrderReceivedModal}
				onClose={() => {
					setShowOrderReceivedModal(false)
					history.replace("/shop")
				}}
				orderNumber={fromOrder}
			/>
		</PageContentWrapper>
	)
}
