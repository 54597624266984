import React, { ReactNode } from "react"

import { css, Theme } from "@emotion/react"

import { Tooltip } from "../tooltips"
import { Icon } from "./Icon"

export interface LabelProps {
	htmlFor?: string
	tooltip?: string | ReactNode
}

export const Label: React.FC<LabelProps> = ({ htmlFor, tooltip, children }) => {
	if (!tooltip) {
		return (
			<label htmlFor={htmlFor} css={labelCss}>
				{children}
			</label>
		)
	}

	return (
		<span css={containerCss}>
			<label htmlFor={htmlFor} css={labelCss}>
				{children}
			</label>
			<Tooltip title={tooltip}>
				<Icon icon="info-circle" fontSize={0.8} color="gray" />
			</Tooltip>
		</span>
	)
}

const containerCss = css`
	display: flex;
	align-items: center;
	gap: 0.4rem;
`

const labelCss = (theme: Theme) => css`
	color: #888;
	font-size: 1rem;
`
